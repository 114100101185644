'use strict'
const PropTypes = require('prop-types')
const React = require('react')

const pluralize = require('../../utils/pluralize')
const ArchiveIcon = require('../icons/archive')
const CogIcon = require('../icons/cog')
const BoxIcon = require('../icons/box')
const BoxesIcon = require('../icons/boxes')
const FileAltIcon = require('../icons/file-alt')
const TagIcon = require('../icons/tag')
const styles = require('../tabs/tabs.css')
const Tabs = require('../tabs/tabs')
const Beta = require('../beta')

const BetaTag = () => <Beta className={styles.tabBetaTag} />

function PackageTabs(props) {
  const {dependentsCount, dependenciesCount, files, versionsCount, active, isMaintainer, packageUrl} = props

  const isAdmin = active === 'admin'
  // TODO: re-enable the admin tab once the UI works consistently for orgs
  const showAdminTab = isMaintainer
  const ariaLabel = 'Use left and right arrow keys to navigate the package tabs'

  const links = [
    {
      key: 'readme',
      href: isAdmin ? packageUrl : null,
      label: (
        <span>
          <FileAltIcon /> Readme
        </span>
      ),
      ariaLabel,
    },
    {
      key: 'code',
      href: isAdmin ? `${packageUrl}?activeTab=code` : null,
      label: (
        <span>
          <ArchiveIcon />
          Code <BetaTag />
        </span>
      ),
      ariaLabel,
    },
    {
      key: 'dependencies',
      href: isAdmin ? `${packageUrl}?activeTab=dependencies` : null,
      label: (
        <span>
          <BoxIcon />
          {dependenciesCount.toLocaleString() + ' ' + pluralize(dependenciesCount)`Dependenc${['ies', 'y']}`}
        </span>
      ),
      ariaLabel,
    },
    {
      key: 'dependents',
      href: isAdmin ? `${packageUrl}?activeTab=dependents` : null,
      label: (
        <span>
          <BoxesIcon />
          {`${dependentsCount.toLocaleString()} Dependents`}
        </span>
      ),
      ariaLabel,
    },
    {
      key: 'versions',
      href: isAdmin ? `${packageUrl}?activeTab=versions` : null,
      label: (
        <span>
          <TagIcon />
          {`${versionsCount.toLocaleString()} Versions`}
        </span>
      ),
      ariaLabel,
    },
    showAdminTab && {
      key: 'admin',
      href: `${packageUrl}/access`,
      label: (
        <span>
          <CogIcon /> Settings
        </span>
      ),
      ariaLabel,
    },
  ].filter(Boolean)
  return <Tabs links={links} colors={['yellow', 'red', 'purple', 'violet', 'teal', 'red']} active={active} />
}

PackageTabs.propTypes = {
  files: PropTypes.object,
  dependentsCount: PropTypes.number.isRequired,
  dependenciesCount: PropTypes.number.isRequired,
  versionsCount: PropTypes.number.isRequired,
  isMaintainer: PropTypes.bool,
  active: PropTypes.string,
  packageUrl: PropTypes.string,
}

PackageTabs.defaultProps = {
  dependentsCount: 0,
  dependenciesCount: 0,
  versionsCount: 0,
  isMaintainer: false,
  active: 'readme',
}

module.exports = PackageTabs
