'use strict'
const React = require('react')
const styles = require('./versions.css')
const PropTypes = require('prop-types')
const Time = require('../time')
const packageStyles = require('../../styles/package.css')
const iconStyles = require('../icons/icons.css')
const Checkbox = require('../inputs/checkbox').unwrapped
const Link = require('@npm/spiferack/link')
const {VerifiedIcon} = require('@primer/octicons-react')
const {StaticProvenancePopover} = require('../../components/package/provenance')

class PackageVersions extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {showDeprecated: false}
  }

  toggleDeprecated() {
    this.setState({showDeprecated: !this.state.showDeprecated})
  }

  versionList(versions, labelById) {
    const {name, versionsDownloads = {}} = this.props
    return (
      <table className={styles.versionTable} aria-labelledby={labelById}>
        <thead>
          <tr>
            <th className="fw6">Version</th>
            <th className="fw6">Downloads (Last 7 Days)</th>
            <th className="fw6">{versions && versions[0] && versions[0].tag ? 'Tag' : 'Published'}</th>
          </tr>
        </thead>
        <tbody>
          {this.sortIfCurrentTags(versions).map(({version, dist, deprecated, date, tag}) => (
            <tr key={tag || version} className={deprecated ? styles.deprecated : ''}>
              <td>
                <Link>
                  <a
                    href={`/package/${name}/v/${version}`}
                    className={styles.versions}
                    aria-label={
                      deprecated
                        ? `deprecated: ${deprecated}`
                        : typeof version === 'string' && version.includes('.')
                          ? version.split('.').join(' .')
                          : version
                    }
                  >
                    {version}
                  </a>
                </Link>
                {dist && dist.attestations && dist.attestations.provenance && (
                  <span className={styles.provenanceCheckmarkContainer}>
                    <StaticProvenancePopover provenanceDetailsUrl={`/package/${name}/v/${version}#provenance`}>
                      <VerifiedIcon className={iconStyles.checkMarkIcon} size={16} />
                    </StaticProvenancePopover>
                  </span>
                )}
              </td>
              <td className="downloads">
                {versionsDownloads[version] !== undefined ? versionsDownloads[version].toLocaleString() : '0'}
              </td>
              <td className={styles.tag}>{tag || <Time at={date} />}</td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  sortIfCurrentTags(versions) {
    const {versionsDownloads = {}} = this.props
    if (versions && versions[0] && versions[0].tag) {
      // if we are in current tags, sort
      return versions.sort((a, b) => {
        if (versionsDownloads[a.version] !== versionsDownloads[b.version]) {
          return versionsDownloads[b.version] - versionsDownloads[a.version]
        } else {
          return ('' + b.version).localeCompare(a.version)
        }
      })
    }
    return versions
  }

  render() {
    const {distTags, versions = [], deprecations} = this.props
    const {showDeprecated} = this.state
    const hasDeprecatedVersions = versions.some(({deprecated}) => deprecated)
    const taggedVersions = Object.keys(distTags)
      .filter(t => !deprecations.includes(distTags[t]))
      .map(tag => {
        return Object.assign(
          {},
          versions.find(v => v.version === distTags[tag]),
          {tag},
        )
      })

    return (
      <div className={styles['versions-container']}>
        <h2 className={styles.srOnly}>Versions</h2>
        <h3 className={packageStyles.sectionHeaderNoLine} id="current-tags">
          Current Tags
        </h3>
        {this.versionList(taggedVersions, 'current-tags')}
        <h3 className={packageStyles.sectionHeaderNoLine} id="version-history">
          Version History
        </h3>
        {hasDeprecatedVersions && (
          <Checkbox
            dispatch={() => {}}
            label="show deprecated versions"
            formId="fake"
            name="showDeprecated"
            onChange={() => this.toggleDeprecated()}
            formData={{value: showDeprecated}}
          />
        )}
        {this.versionList(
          versions.filter(({deprecated}) => showDeprecated || !deprecated),
          'version-history',
        )}
      </div>
    )
  }
}

PackageVersions.propTypes = {
  distTags: PropTypes.object.isRequired,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.object,
      version: PropTypes.string,
    }),
  ).isRequired,
  deprecations: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  versionsDownloads: PropTypes.shape({
    [PropTypes.string]: PropTypes.number,
  }),
}

PackageVersions.defaultProps = {}

module.exports = PackageVersions
