const React = require('react')
const styles = require('./types-icon.css')

class TypeScriptIcon extends React.PureComponent {
  render() {
    return (
      <div data-nosnippet>
        <img
          src={require('./img/typescript-logo.svg')}
          height="20px"
          title="This package contains built-in TypeScript declarations"
          alt="TypeScript icon, indicating that this package has built-in type declarations"
          className={styles['types-icon']}
          data-nosnippet
        />
      </div>
    )
  }
}

module.exports = TypeScriptIcon
