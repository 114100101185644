const React = require('react')
const PropTypes = require('prop-types')
const styles = require('./types-icon.css')

class DefinitelyTypedIcon extends React.PureComponent {
  render() {
    const {packageName} = this.props

    return (
      <div data-nosnippet>
        <a href={`/package/${packageName}`} className="flex">
          <img
            src={require('./img/definitelytyped-logo.svg')}
            height="20px"
            title={`This package has TypeScript declarations provided by ${packageName}`}
            alt={`DefinitelyTyped icon, indicating that this package has TypeScript declarations provided by the separate ${packageName} package`}
            className={styles['types-icon']}
          />
        </a>
      </div>
    )
  }
}

DefinitelyTypedIcon.propTypes = {
  packageName: PropTypes.string.isRequired,
}

module.exports = DefinitelyTypedIcon
