const React = require('react')
const ReactDOM = require('react-dom/server')
const styles = require('./markdown.css')
const legacyStyles = require('./markdown-legacy.css')
const {LinkIcon} = require('@primer/octicons-react')

// Generated markdown used to contain inline SVG link icons. Since new markdown
// content does not contain these SVGs we do a clientside only replacement for
// empty octicon-link spans and replace them with the octicon-link SVG. This
// could also be done in the esi-proxy service but that would require
// regenerating many readmes.
const linkHtml = ReactDOM.renderToStaticMarkup(<LinkIcon verticalAlign="middle" />)
const replaceLinkIcons = parentNode => {
  const nodes = parentNode.querySelectorAll('span.octicon.octicon-link:empty')
  for (const node of nodes) {
    node.outerHTML = linkHtml
  }
}

const makeLinkIconsFocusable = parentNode => {
  const nodes = parentNode.querySelectorAll('a.anchor[tabindex="-1"]')
  for (const node of nodes) {
    node.removeAttribute('tabindex')
  }
}

const makePreElementsFocusable = parentNode => {
  const nodes = parentNode.getElementsByTagName('pre')
  for (const node of nodes) {
    node.setAttribute('tabindex', 0)
  }
}

const replaceSrcWithCanonicalSrc = e => {
  const node = e.currentTarget
  // Only run this handler once even if the canonical src ends up erroring
  // since there is nothing else we can do about a broken src at that point
  node.removeEventListener('error', replaceSrcWithCanonicalSrc)
  const canonicalSrc = node.getAttribute('data-canonical-src')
  // Only replace src attributes if they are https or protocol relative.
  // Anything else will cause protocol mismatch errors since we can assume we
  // are on an https page. The tradeoff is that http images will remain broken.
  if (canonicalSrc.startsWith('https://') || canonicalSrc.startsWith('//')) {
    node.setAttribute('src', canonicalSrc)
  }
}

// Ref https://github.com/github/npm/issues/11164
// Temporary fix to replace broken images with their data-canonical-src attribute
const fixBrokenImagesWithCanonicalSrc = parentNode => {
  const nodes = parentNode.querySelectorAll('img[src][data-canonical-src]')
  for (const node of nodes) {
    if (!node.complete) {
      // If image loading is not complete, then we wait for an error event to replace the src
      node.addEventListener('error', replaceSrcWithCanonicalSrc)
    } else if (node.naturalHeight === 0) {
      // Otherwise if the image has completed loading but has no naturalHeight, we know it errored
      replaceSrcWithCanonicalSrc({currentTarget: node})
    }
  }
}

const useMarkdown = markdown => {
  const ref = React.useRef()

  React.useEffect(() => {
    const {current: parentNode} = ref
    if (parentNode) {
      replaceLinkIcons(parentNode)
      makePreElementsFocusable(parentNode)
      makeLinkIconsFocusable(parentNode)
      fixBrokenImagesWithCanonicalSrc(parentNode)
    }
  }, [ref, markdown])

  return {
    ref,
    dangerouslySetInnerHTML: {__html: markdown},
  }
}

function Markdown({markdown, id}) {
  return <div id={id} className={`${legacyStyles.markdown} ${styles.markdown}`} {...useMarkdown(markdown)} />
}

module.exports = Markdown
