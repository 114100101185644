'use strict'

const React = require('react')
const StaticComponent = require('../static')
const styles = require('./icons.css')

class FileIcon extends StaticComponent {
  render() {
    return (
      <img
        alt=""
        className={styles.fileIcon}
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTI1IiBmaWxsPSJncmF5Ij48cGF0aCBkPSJNODIuMiAxMC43SDM4LjdsLTIwLjkgMTl2NTkuNmg2NC40em0tNDQuNiA2LjR2MTEuMkgyNS4zem00MC42IDY4LjJIMjEuOHYtNTNoMTkuOFYxNC43aDM2LjZ6Ii8+PHBhdGggZD0iTTM0LjMgNDQuN2gzMS40djRIMzQuM3pNMzQuMyA1Ny45aDMxLjR2NEgzNC4zek0zNC4zIDcxaDMxLjR2NEgzNC4zeiIvPjwvc3ZnPg=="
      />
    )
  }
}

module.exports = FileIcon
