const React = require('react')
const styles = require('./dependency.css')

class Dependency extends React.PureComponent {
  render() {
    const {package: pkg} = this.props
    return (
      <li className="dib mr2">
        <a href={`/package/${encodeURIComponent(pkg)}`} className={styles.sectionWordCloud}>
          {pkg}
        </a>
      </li>
    )
  }
}

module.exports = Dependency
