const React = require('react')
const StaticComponent = require('../static')
const styles = require('./icons.css')

class BackIcon extends StaticComponent {
  render() {
    return (
      <img
        alt=""
        className={styles.backIcon}
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTI1IiBmaWxsPSI4MDgwODAiPjxwYXRoIGQ9Ik01MC40IDc0LjdMMjMuNyA1MGwyNi43LTI0LjctNS40LTUuOUwxMS45IDUwIDQ1IDgwLjZsNS40LTUuOXoiLz48cGF0aCBkPSJNODIuNiAxOS40TDQ5LjYgNTBsMzMgMzAuNiA1LjUtNS45TDYxLjQgNTBsMjYuNy0yNC43LTUuNS01Ljl6Ii8+PC9zdmc+"
      />
    )
  }
}

module.exports = BackIcon
