'use strict'

const Link = require('@npm/spiferack/link')
const React = require('react')

const GithubStats = require('./github-stats')
const Downloads = require('./downloads')
const License = require('./license')
const bytesToSize = require('pretty-bytes')
const FlagIcon = require('../icons/flag')
const InstallIcon = require('../icons/install')
const LinkIcon = require('../icons/link')
const ReplIcon = require('../icons/repl')
const HeartIcon = require('../icons/heart')
const iconStyles = require('../icons/icons.css')
const {VerifiedIcon, StopIcon, AlertIcon} = require('@primer/octicons-react')
const EZCopy = require('../../components/ez-copy/ez-copy')
const {ProvenancePopover} = require('../../components/package/provenance')
const Avatar = require('@npm/design-system/avatar/avatar')
const styles = require('../../styles/package.css')
const forms = require('../../styles/forms.css')
const RepoLink = require('./repo-link')
const Time = require('../../components/time')
const PackageLinkingButton = require('../package-linking/package-linking-button')

function Sidebar(props) {
  const {
    name,
    downloads,
    version,
    attestations,
    license,
    homepage,
    repoLink,
    funding,
    dist,
    versions = [],
    maintainers,
    pkg,
    provenance,
    ghapi,
    isLatest,
    private: isPrivate,
    scope,
    linkingAllowedForPackage,
  } = props

  const packageName = isLatest ? name : `${name}@${version}`

  return (
    <div className={styles.rightSidebar}>
      <h2 className={styles.srOnly}>Package Sidebar</h2>
      <h3 className={styles.sidebarHeader}>Install</h3>
      <div className={`${styles.sidebarCode} ${styles.install} flex flex-row`}>
        <InstallIcon />
        <p className="flex-auto truncate db ma0">
          <EZCopy aria-label={'Copy install command line'}>npm i {packageName}</EZCopy>
        </p>
      </div>
      {repoLink && (
        <RepoLink repoLink={repoLink}>
          {linkingAllowedForPackage && (
            <PackageLinkingButton className={styles.linkingButton} package={{name}} scope={scope} />
          )}
        </RepoLink>
      )}
      {homepage && (
        <div className={`${styles.sidebarSection} w-100`}>
          <h3 id="homePage" className={styles.sidebarHeader}>
            Homepage
          </h3>
          <p className={styles.sidebarTextSmall}>
            <a
              aria-labelledby="homePage homePage-link"
              className={styles.sidebarLink}
              rel="noopener noreferrer nofollow"
              href={homepage}
            >
              <LinkIcon />
              <span id="homePage-link">{homepage.replace(/(^\w+:|^)\/\//, '')}</span>
            </a>
          </p>
        </div>
      )}
      {funding && (
        <div className={styles.buttons}>
          <Link>
            <a
              className={`${forms.buttonGradient} mt2 dib w-100`}
              rel="noopener noreferrer nofollow"
              href={funding.url}
            >
              <HeartIcon />
              <strong>Fund</strong> this package
            </a>
          </Link>
        </div>
      )}
      {isLatest && <div>{downloads && downloads.length ? <Downloads data={downloads} key={pkg} /> : null}</div>}
      <div className={styles.sidebarSection}>
        <h3 className={styles.sidebarHeader}>Version</h3>
        <div className={styles.sidebarVersionCheckContainer}>
          <p className={styles.sidebarText}>{version}</p>
          {provenance && provenance.enabled && attestations && attestations.provenance && (
            <div className={styles.sidebarVersionCheckIconLink}>
              {/* Note: <Scroll /> to content behaviour on the package page requires the target
                   element to have an id matching `#user-content-${window.location.hash */}
              <ProvenancePopover
                provenance={provenance}
                provenanceDetailsUrl={
                  isLatest ? `/package/${name}#provenance` : `/package/${name}/v/${version}#provenance`
                }
              >
                {provenance.details && provenance.details.sourceCommitUnreachable ? (
                  provenance.details.sourceCommitNotFound ? (
                    <StopIcon className={iconStyles.stopIcon} size={16} />
                  ) : (
                    <AlertIcon className={iconStyles.warningIcon} size={16} />
                  )
                ) : (
                  <VerifiedIcon className={iconStyles.checkMarkIcon} size={16} />
                )}
              </ProvenancePopover>
            </div>
          )}
        </div>
      </div>
      <div className={styles.sidebarSection}>
        <h3 className={styles.sidebarHeader}>License</h3>
        <p className={styles.sidebarText}>
          <License license={license} />
        </p>
      </div>
      {dist.unpackedSize && (
        <div className={styles.sidebarSection}>
          <h3 className={styles.sidebarHeader}>Unpacked Size</h3>
          <p className={styles.sidebarText}>{bytesToSize(dist.unpackedSize)}</p>
        </div>
      )}
      {dist.fileCount && (
        <div className={styles.sidebarSection}>
          <h3 className={styles.sidebarHeader}>Total Files</h3>
          <p className={styles.sidebarText}>{dist.fileCount}</p>
        </div>
      )}
      {ghapi && <GithubStats ghapi={ghapi} key={pkg} />}
      {Boolean(versions.length) && versions[0] && versions[0].date && (
        <div className={`${styles.sidebarSection} w-100`}>
          <h3 className={styles.sidebarHeader}>Last publish</h3>
          <p className={styles.sidebarText}>
            <Time at={versions[0].date} />
          </p>
        </div>
      )}
      {maintainers && (
        <div className={`${styles.sidebarSection} bb-0 w-100`}>
          <h3 id="collaborators" className={styles.sidebarHeader}>
            Collaborators
          </h3>
          <ul aria-labelledby="collaborators" className="list pl0 cf">
            {maintainers.map(xs => (
              <li key={xs.name} className={`${styles.maintainer} fl dib mr2 mb2`}>
                <Avatar src={xs.avatars.medium} size={42} href={`/~${xs.name}`} title={xs.name} />
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="w-100">
        <Link>
          <a
            className={`${forms.buttonGradientGreen} mt2 dib w-100`}
            rel="noopener noreferrer nofollow"
            href={`https://runkit.com/npm/${encodeURIComponent(name)}`}
          >
            <ReplIcon />
            <strong>Try</strong> on RunKit
          </a>
        </Link>
      </div>
      {!isPrivate && (
        <div className={styles.buttons}>
          <Link>
            <a
              className={`${forms.buttonGradientRed} mt2 dib w-100`}
              href={`/support?inquire=security&security-inquire=malware&package=${encodeURIComponent(
                name,
              )}&version=${version}`}
            >
              <FlagIcon />
              <strong>Report</strong> malware
            </a>
          </Link>
        </div>
      )}
    </div>
  )
}

module.exports = Sidebar
