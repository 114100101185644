const React = require('react')
const Markdown = require('../markdown/markdown')
require('github-syntax-light/lib/github-light.css')
const styles = require('../../styles/package.css')

function Readme({id, data, showReadme}) {
  return (
    <div className={showReadme ? '' : styles.readmeHidden}>
      {/* <article> is in order to improve snippet generation in Google */}
      <article>
        <Markdown markdown={data} id={id} />
      </article>
    </div>
  )
}

module.exports = Readme
